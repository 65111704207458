

import NavLink from "./NavLink";
import NavLogo from "./NavLogo";
import { StyledNavbar } from "../Styles/StyledNavbar";
import { StyledNavbarLinks } from "../Styles/StyledNavbarLinks";

interface NavProps {
  className: string;
  src: string;
  showLogin?: boolean;
}

const Navbar = ({ className, src, showLogin = false }: NavProps) => {

  return (
    <StyledNavbar className={className}>
      <NavLogo src={src} />
      <StyledNavbarLinks>
        <NavLink href='https://app.ats.tofo.cloud/'  isActive={false}>
            Přihlášení učitele
        </NavLink>
      </StyledNavbarLinks>
    </StyledNavbar>
  );
};

export default Navbar;
