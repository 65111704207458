import Img from "./Img";
import { StyledLogoGrid } from "../Styles/StyledLogoGrid";

const partnerLogos = [
  {
    logoSrc: "/partnersm/ckp.svg",
    url: "https://www.ckp.cz/"
  },
  {
    logoSrc: "/partnersm/tsb_white.svg",
    url: "https://www.tymbezpecnosti.cz/"
  },
  {
    logoSrc: "/partnersm/novotny.svg",
    url: "https://www.novotny-autoskola.cz/"
  },
  {
    logoSrc: "/partnersm/koop.svg",
    url: "https://www.koop.cz/"
  },
  {
    logoSrc: "/partnersm/liberec.svg",
    url: "https://www.kraj-lbc.cz/"
  },
  {
    logoSrc: "/partnersm/pcr.svg",
    url: "https://www.policie.cz/"
  },
  {
    logoSrc: "/partnersm/aacr.svg",
    url: "https://www.asociaceautoskol.cz/"
  },
  {
    logoSrc: "/partnersm/arc.svg",
    url: "https://www.autoklub.cz/"
  },
  {
    logoSrc: "/partnersm/inmovie.svg",
    url: "https://www.inmovefilms.com/"
  },
  {
    logoSrc: "/partnersm/rst.svg",
    url: "https://www.rstmoto.cz/"
  },
  {
    logoSrc: "/partnersm/slk.svg",
    url: "https://www.silnicelk.cz/"
  },
  {
    logoSrc: "/partnersm/brod.svg",
    url: "https://www.zeleznybrod.cz/cz/"
  },
];

const LogoGrid = () => {
  return (
      <div className={"footer__partners"}>
        <h2>projekt vznikl ve spolupráci s</h2>
        {partnerLogos.map((partnerLogo, i) => {
          return <a style={{flex: " 1 0 calc(23% - 10px)"}} href={partnerLogo.url} target={"_blank"}> <Img key={i}
                                                                                                           src={partnerLogo.logoSrc}/></a>;
        })}
      </div>
  );
};

export default LogoGrid;
