import { StyledFooter, FooterInfo } from "../Styles/StyledFooter";
import Navbar from "./Navbar";
import Img from "./Img";
import { BodyText } from "../Typo/BodyText";
import {PartnersSection} from "../Styles/StyledLandingPage";
import {BlockHeader} from "../Typo/BlockHeader";
import LogoGrid from "./LogoGrid";
import React from "react";

const Footer = () => {
  return (
      <StyledFooter className={"footer"}>
          <div className="footer__contact"><h1>Napište nám</h1>
              <div><h2>e-mail</h2><p>info@tymbezpecnosti.cz</p><h2>telefon</h2><p>+420&nbsp;776&nbsp;844&nbsp;388</p>
              </div>
          </div>
          <LogoGrid/>
      </StyledFooter>
  );
};

export default Footer;
