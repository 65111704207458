import React from 'react';
import { Fragment } from "react";

import './App.css';
import {Intro, PartnersSection, StyledLandingPage} from "./components/Styles/StyledLandingPage";
import Navbar from "./components/Items/Navbar";
import Banner from "./components/Items/Banner";
import Button from "./components/Items/Button";
import {PageHeader} from "./components/Typo/PageHeader";
import {BlockQuote} from "./components/Typo/BlockQuote";
import Footer from "./components/Items/Footer";
import LogoGrid from "./components/Items/LogoGrid";
import {BlockHeader} from "./components/Typo/BlockHeader";
import {Link} from "react-router-dom";
import Videos from "./videos";
import NavLink from "./components/Items/NavLink";


function About() {
    return (
        <Fragment>
            <StyledLandingPage>
                <div className="c about">
                    <div className="about__intro">
                        <div className="about__brand"><Link to={"/"}> <img
                            src="http://cdn.ucmeseprezit.cz/assets/img/ucmeSePrezitBlack.svg" alt=""/></Link></div>
                        <h1>O&nbsp;projektu</h1><p>Aplikaci pro vás připravila autoškola Jiří Novotný a Tým silniční
                        bezpečnosti. Učme se přežít je projekt realizovaný v České republice od roku 2010 a zaměřuje se
                        na snížení počtu a zejména následků nehod motocyklistů. Projekt je spolufinancován z fondu
                        zábrany škod České kanceláře pojistitelů.</p><p>Učme se přežít je název projektu, kterým se
                        snažíme snížit počet a zejména pak následky dopravních nehod motocyklistů. Jsme přesvědčeni, že
                        nejefektivnější cestou, jak toho docílit, je trénink v prostředí bezpečných autodromů a
                        polygonů, zapojení autoškol, tvorba efektivních pomůcek a efektivní komunikace.</p><p>Za tímto
                        účelem jsme sestavili projekt, který již má svou historii a výsledky a který se zaměřuje na to
                        nejdůležitější, co by každý motocyklista měl bezpodmínečně znát a umět.</p><p>Učme se přežít je
                        projektem, který otevírá cestu k uvědomění si, jaká rizika na motocyklistu v provozu čekají, co
                        vše je vlastně nutné si uvědomit a co vše je nutné trénovat. Absolvováním kurzu „Učme se přežít“
                        cesta zdokonalování řidiče nekončí, ale spíše začíná.</p></div>
                    <div className="about__bullets"><h2>Aktivity&nbsp;projektu</h2>
                        <ol>
                            <li>Realizace kurzů a seminářů pro učitelé autoškol s cílem seznámení s pokročilými
                                technikami řízení motocyklu a potenciálem nadstavbových kurzů určených pro motocyklisty
                            </li>
                            <li>Zapojení autoškol. Realizace speciálních kurzů pro autoškoly a vytváření online pomůcek
                                pro vnímání rizik použitelných nejen v autoškolách
                            </li>
                            <li>Prostřednictvím kampaně popularizovat nadstavbové kurzy (i komerční) motocyklistů nad
                                rámec základního výcviku v autoškolách.
                            </li>
                            <li>Prostřednictví PR informování účastníků silničního provozu o specifikách jízdy na
                                motocyklu, závažnosti nehod s účastí motocyklu, nacvičování praktických dovedností,
                                zvýšení vzájemné ohleduplnosti motocyklistů a ostatních účastníků siln. provozu.
                            </li>
                            <li>Multiplikace preventivních aktivit pro motocyklisty v ČR, zapojení ostatních dotčených
                                subjektů veřejné správy a subjektů ze soukromého sektoru
                            </li>
                        </ol>
                    </div>
                </div>


                <Footer/>
            </StyledLandingPage>
        </Fragment>
    );
}

export default About;
