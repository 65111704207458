
export const colors: Record<string, string> = {
  white: "#FFFFFF",
  black: "#000000",
  lightGray: "#EDEDED",
  gray500: "#262626",
  gray400: "#707070",
  gray300: "#7B7676",
  gray200: "#6F6F6F",
  orange400: "#E6B700",
  orange600: "#ff4d00",
  yellow400: "#FFBA00",
  violet400: "#5C00EF",
  green300: "#BFFFBF",
  green400: "#00B107",
  red: "red",
} as const;

export type ColorKeys = keyof typeof colors;
export type VideoType = "hp" | "dp";



export const device = {
  miniPhone: 350,
  phone: 600,
  tabletPortrait: 900,
  tabletLandscape: 1200,
  smallNotebook: 1400,
  largeNotebook: 1920,
  monitor: 1921,
};

export const breakpoint = {
  miniPhone: `@media (max-width: ${device.miniPhone}px)`,
  phone: `@media (max-width: ${device.phone}px)`,
  phoneLandscape: `@media (max-height: ${device.phone}px) and (orientation: landscape)`,
  tabletPortrait: `@media (max-width: ${device.tabletPortrait}px) `,
  tabletLandscape: `@media (max-width: ${device.tabletLandscape}px) `,
  smallNotebook: `@media (max-width: ${device.smallNotebook}px)`,
  largeNotebook: `@media (max-width: ${device.largeNotebook}px)`,
  monitor: `@media (min-width: ${device.monitor}px) `,
};

export const mainTagData = {
  hp: {
    color: `yellow400`,
    text: `Hazard perception`,
    instructions: [
      "Pomocí kliknutí zastav video v momentě nebezpečí.",
      "Poté označ jedno nebo více nebezpečí.",
      "Body získáváš za rychlou reakci a správné označení nebezpečí.",
      "Zbytečně neklikej kde nic nehrozí, jinak nedojedeš",
    ],
    introduction: {
      header: `Každodenní situace v provozu`,
      perex: `Zjistěte, jestli umíte řešit nejčastější důvody dopravních nehod na českých silnicích. `,
    },
  },
  dp: {
    color: `violet400`,
    text: `Dynamic perception`,
    instructions: [
      "Pomocí kliknutí zastav video v momentě nebezpečí.",
      "Poté označ jedno nebo více nebezpečí.",
      "Body získáváš za rychlou reakci a správné označení nebezpečí.",
      "Zbytečně neklikej kde nic nehrozí, jinak nedojedeš",
    ],
    introduction: {
      header: "Jeden test, různé scénáře",
      perex:
        "Buďte ve střehu. Každá varianta videa obsahuje jiná nebezpečí. Co je jednou bezpečné podruhé být nemusí. Stejně jako v reálném provozu.",
    },
  },
} as const;

export const INITIAL_ITEMS_PER_PAGE = 2;
export const ITEMS_PER_FETCH = 1;
