import React from 'react';
import { Fragment } from "react";

import './App.css';
import {Intro, PartnersSection, StyledLandingPage} from "./components/Styles/StyledLandingPage";
import Navbar from "./components/Items/Navbar";
import Banner from "./components/Items/Banner";
import Button from "./components/Items/Button";
import {PageHeader} from "./components/Typo/PageHeader";
import {BlockQuote} from "./components/Typo/BlockQuote";
import Footer from "./components/Items/Footer";
import LogoGrid from "./components/Items/LogoGrid";
import {BlockHeader} from "./components/Typo/BlockHeader";
import {Link} from "react-router-dom";
import Videos from "./videos";
import NavLink from "./components/Items/NavLink";


function App() {
  return (
      <Fragment>
          <StyledLandingPage>
              <Navbar src={"https://cdn.ucmeseprezit.cz/assets/img/ucmeSePrezit.svg"} className={"white"}/>

                  <Banner imgUrl={"https://cdn.ucmeseprezit.cz/assets/img/HERO_LOOP.mp4"} >
                      <div className="hero__content">
                      <PageHeader>
                          Naučíme vás řešit nebezpečí na cestách
                      </PageHeader>
                      <p className="big">Motorkáři jsou na silnicích nejohroženější účastníci provozu. Učte se s námi
                          předvídat rizika a předcházejte tak krizovým situacím.</p>

                          <Button onClick={()=>{
                              const element = document.getElementById("videos");
                              if (element) {
                                  element.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the element
                              }
                          }}
                              className='primary big'>
                              Vyzkoušet
                          </Button>

                          <Link to='about'  className={"link white"} style={{color:"white"}}>
                              VÍCE O PROJEKTU
                          </Link>
                      </div>



                </Banner>


          <section className="mission"><h2>REÁLNÁ NEBEZPEČÍ NA ČESKÝCH SILNICÍCH</h2><p className="big">Natočili
                  jsme nejčastější hrozby, které vás mohou potkat, abyste si je mohli vyzkoušet a naučit se na ně
                  reagovat.</p><Link className="link black" to="about">Více o projektu</Link></section>

              <Videos/>


              <Footer/>
          </StyledLandingPage>
      </Fragment>
  );
}

export default App;
