import styled from "styled-components";
import { breakpoint } from "../../helpers/consts";
import u from "../../helpers/unit";

export const StyledLogoGrid = styled.div`
  width: 100%;
  padding-top: 100px;
  display: flex;
  flex-wrap: wrap;
  //grid-template-columns: repeat(4, 1fr);
  //gap: 20px;
  // white is used for black and white color logotypes with blending mode
  //background-color: white;

  ${breakpoint.tabletLandscape} {
    //grid-template-columns: repeat(3, 1fr);
  }
  ${breakpoint.tabletPortrait} {
    //grid-template-columns: repeat(2, 1fr);
    //gap: 30px;
  }
`;


