import styled from "styled-components";
import { colors, breakpoint } from "../../helpers/consts";
import { StyledBanner } from "../Styles/StyledBanner";
import u from "../../helpers/unit";
import { StyledVideosPage } from "../Styles/StyledVideosPage";
import { StyledLogin } from "../Styles/StyledLogin";

export const PageHeader = styled.h1`
  font-size: 50px;
  line-height: 1.1em;
  font-weight: 600;
  color: ${colors.white};
  letter-spacing: -0.02em;
  ${StyledLogin} & {
    color: ${colors.black};
    font-size: 3rem;
  }
  ${StyledBanner} & {
    margin-bottom: 50px;
    max-width: ${u(8)};
    ${breakpoint.tabletLandscape} {
      max-width: ${u(12)};
      font-size: 5rem;
    }
    ${breakpoint.phoneLandscape} {
      font-size: 3rem;
      margin-bottom: 30px;
    }
    ${breakpoint.phone} {
      max-width: ${u(14)};
      font-size: 3.5rem;
    }
  }
  ${StyledVideosPage} & {
    color: ${colors.black};
    font-size: 5rem;
  }
`;
